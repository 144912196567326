import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import * as cn from 'classnames'

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleDropdownClick = (event) => {
      console.log('clicked')
      event.preventDefault();
      event.stopPropagation();
      // dropdownMenu.parentElement.classList.toggle('show');
      setShowDropdown(!showDropdown)
    }

  const handleDropdownItemClick = (event) => {
    setShowDropdown(!showDropdown)
  }

  console.log('showDropdown', showDropdown)

  // data-toggle="collapse"
  // data-target="#navbarNavDropdown"
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">Peter Conerly</a>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation" 
          onClick={handleDropdownClick}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={cn("navbar-collapse collapse", { 'show': showDropdown})} id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className={cn("nav-link dropdown-toggle")}
                onClick={handleDropdownClick}
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Sections
              </a>
              <div
                className={cn("dropdown-menu",  { 'show': showDropdown})}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#whoiam">Who I am</a>
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#work">Work History</a>
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#education">Education</a>
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#skills">Skills</a>
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#projects">Projects</a>
                <a className="dropdown-item" onClick={handleDropdownItemClick} href="#opensource">Open Source Contributions</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
