import { isNil } from 'lodash'

const items = [
  {
    "name": "Gordian Tools LLC", 
    "job_title": "Freelance Contractor",
    "time_period": "Oct 2021 - Present",
    description: [
      "Worked on a variety of web development projects",
      "Wrote JS/React frontends to interact with Ethereum smart contracts",
      "Wrote Smart Contracts on the Ethereum Blockchain for Non-Fungible Tokens",
      "Wrote Python/Django backends to interact with smart contracts and host off-chain data in coordination with dapps (“decentralized apps”)"]
    },
  {
    "name": "WebDiff.io",
    "job_title": "Principial",
    "time_period": "Jan 2019 - Sept 2021",
    description: ["Built a QA tool to help track changes to websites.",
    "Investigated and prototyped several diffing algorithms, eventually came to using my own variant of a common algorithm.",
    "Hired and trained a junior engineer.",
    "After finding several bugs and missing features in `html-parse-stringify`, I wrote my own HTML parser. My parser is 10% faster and parses anything from the HTML spec."]
  },
  {
    "name": "PicMonkey",
    "job_title": "Senior Software Engineer",
    "time_period": "Aug 2014 - Aug 2018",
    "description": [
      "I was one of the primary engineers for the JavaScript and Django/Python side of picmonkey.com. I learned Chef and helped with DevOps, including having pager duty.",
      "Was on a team to rewrite a Flash photo editor to JavaScript/WebGL. I learned WebGL shaders and worked with a team to architect an interactive JavaScript application. The project was estimated to take 18 months, and we completed it in 21 months, which is a fantastic outcome.",
      "Onboarded several engineers of varying experience and was an information resource for marketing and product questions about the website."
    ]
  },
  {
    "name": "Moz",
    "job_title": "Software Engineer",
    "time_period": "Jan 2013 - July 2014",
    "description": [
      "I was a primary contributor for Moz Data Warehouse, an internal tool for our help team.",
      "Developed from scratch in Node.js / CoffeeScript / Backbone.js / SlimPHP",
      "Integrated Zuora (a CRM) into the MDW and Moz.com.",
      "Worked on the redesign of Moz.com, the public facing site written in CakePHP."
    ]
  },
  {
    "name": "Freelance Software Developer",
    "job_title": "",
    "time_period": "May 2012 - Jan 2013",
    "description": [
      "Launched PaletteComp.com, a designer-focused side project to test out color themes.",
      "Freelanced for Gratafy, a social gifting startup. I quickly fixed front-end bugs on the first day I was there, their launch day. I ported their .NET MVC site to Django/Mezzanine for better CMS."
    ]
  },
  {
    "name": "Tag Creative",
    "job_title": "Software Developer",
    "time_period": "Feb. 2011 - May 2012",
    "description": [
      "Was on the IEGallery.com development team. We launched in May 2011. Did upgrades including migrating from .NET 2.0 webforms to .NET 4.0 MVC and moved the hosting to Azure.",
      "Wrote the Android app for KEXP radio as a three-month pro-bono project.",
      "This was my first programming job. I started with a beginner’s knowledge of Python and ended with proficiency in HTML, CSS, JavaScript, Android, Java, C#, and .NET. I learned all of these technologies on the job and under deadlines."
    ]
  }
]

const MainContent = () => {
  let itemsContent = items.map((item, index) => {
    return (
      <div key={index} className='work'>
        <p>
          <span><strong>{item.name}</strong> | </span>
          {item.job_title && (<span>{item.job_title} | </span>)}
          {item.time_period && (<span>{item.time_period}</span>)}
        </p>
        {item.description && (
          <ul>
            {item.description.map((line, lineIndex) => (
              <li key={lineIndex}>{line}</li>
            ))}
          </ul>
        )}
        <hr/>
      </div>
    );
  });

  return (
    <div key='work' className=''>
      <a id='work'>
        <h2 id='work'>Work History</h2>
      </a>
      {itemsContent}
    </div>
  );
};

export default MainContent