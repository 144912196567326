import logo from './logo.svg';
import './App.css';
import Content from './Content';
import TableOfContent from './TableOfContent';
import Navbar from './Navbar';

function App() {
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <Content />
        <TableOfContent />
      </div>
    </>
  );
}

export default App;
