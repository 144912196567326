import { isNil } from 'lodash'

const items = [
  {
    "title": "B.S., Biochemistry",
    "institution": "Worcester Polytechnic Institute",
    "location": "Worcester, MA",
    "year": "2009",
    description: [
      'Graduated with Honor',
      'Minored in Entrepreneurship',
      `My Senior Project, “Cloning and Expression of PCV1 ORF3”  won Second Best Senior Research Project in the department of Biochemistry. My partner and I proved that PCV1's gene at ORF3 contained a functional Nuclear Export Signal.`,
    ]
  },
  {
    "title": "Certificate of Python Programming",
    "institution": "University of Washington",
    "location": "Seattle, WA",
    "year": "2011"
  },
  {
    "title": "Algorithms: Design and Analysis",
    "institution": "Coursera.org",
    "location": "",
    "year": "2012"
  },
  {
    "title": "Writing for Business",
    "institution": "Coursera.org",
    "location": "",
    "year": "2020"
  }
]

const MainContent = () => {

  let itemsContent = items.map((item, index) => {

    return (
      <div key={index} className='education'>
        <p><strong>{item.title}</strong> | <span>{item.institution}</span>{item.location && (<span> | {item.location}</span>)}<span> | {item.year}</span></p>
        
        {item.description && (
          <ul>
            {item.description.map((line, lineIndex) => (
              <li key={lineIndex}>{line}</li>
            ))}
          </ul>
        )}
        <hr/>
      </div>
    )})

  return (
    <div key={'education'} className=''>
      <a id="education">
        <h2 id="education">Education</h2>
        </a>
      { itemsContent }
    </div>
  )
}

export default MainContent