import { isNil } from 'lodash'

const projects = [{
  name: 'spectrograph_viewer',
  url: 'https://github.com/pconerly/spectrograph_viewer',
  description: (<>
    <p className="font-weight-bold"><strong>
    Desktop program to view a spectograph of an mp3
    </strong>
    </p>
    <p>
    I'm interested in python desktop apps, but I've been dissapointed by the expensive commercial license for QT and the limitations of TKinter. I wrote this to get familiar with <a href="/#oss-repo-5">pyimgui</a>, and to explore digital analysis of music.</p>
  </>)
}, {
  name: 'html-tokenparser',
  description: (<>
    <p className="font-weight-bold"><strong>
    An html tokenizer and parser written in javascript.
    </strong>
    </p>
    <p>
    I wrote this library to satisfy a need at WebDiff.io to parse html and get the exact strings of the starting and ending tags. I had been using <a href="https://github.com/HenrikJoreteg/html-parse-stringify" target="_blank">html-parse-stringify</a> </p>
  </>)
},  {
  name: 'lc (aka list commands)',
  url: 'https://github.com/pconerly/lc-listcommands-bash',
  description: (<>
    <p className="font-weight-bold"><strong>
    Directory-specific bash history
    </strong>
    </p>
  </>)
},{
  name: 'Todo Helper',
  url: 'https://github.com/p4r4digm/todo-helper',
  description: (<>
    <p className="font-weight-bold"><strong>
    A helpful tool to assist with tracking TODOs on GitHub projects.
    </strong>
    </p>
    <p>
    A prank project. My friend and I were wryly dissapointed in the number of <code>// TODO</code> comments in open source projects. We wrote an automated python parser to search for TODO statements (looking for comments using pygments, a syntax highlighter) and then post issues for the TODOs to be fixed. We filed several hundred tickets before our posting account was shadowbanned by GitHub.</p>
  </>)
} ]

const MainContent = () => {

  let projectsContent = projects.map((project, index) => {
    let title = project.name
    if (!isNil(project.url)) {
      title = <a target="_blank" href={project.url}>{project.name}</a>
    }
    return (
      <div key={index} className='project'>
        <h3 id={`project-${index}`}>{title}</h3>
        {project.description}
        <hr/>
      </div>
    )})

  return (
    <div key={'projects'} className=''>
      <a id="projects">
        <h2 id="projects">Projects</h2>
        </a>
      { projectsContent }
    </div>
  )
}

export default MainContent
