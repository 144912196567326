import { isNil } from 'lodash'

import {ReactComponent as FullStar} from './assets/glyphicons-basic-49-star.svg';
import {ReactComponent as EmptyStar} from './assets/glyphicons-basic-50-star-empty.svg';

const skills = [{
  name: 'Python',
  stars: 5,
}, {
  name: 'git',
  stars: 5,
},{
  name: 'CSS',
  stars: 4,
},{
  name: 'TypeScript',
  stars: 5,
},{
  name: 'Django',
  stars: 4,
},{
  name: 'OpenCV',
  stars: 3,
},{
  name: 'AWS',
  stars: 3,
},{
  name: 'Solidity',
  stars: 3,
},{
  name: 'PyImgui',
  stars: 3,
},{
  name: 'OpenGL',
  stars: 2,
}]


// const fullStar = require('./assets/glyphicons-basic-49-star.svg')
// const emptyStar = require('./assets/glyphicons-basic-50-star-empty.svg')

const StarComponent = ({ stars }) => {

  const starsContent = [];
  for (let i = 0; i < stars; i++) {
    starsContent.push(<span className='skill-star' key={`fullstar-${i}`}><FullStar /></span>);
  }
  // for (let i = stars; i < 5; i++) {
  //   starsContent.push(<span className='skill-star' key={`emptystar-${i}`}><EmptyStar /></span>);
  // }

  return <span>{starsContent}</span>;
};

const ProjectsContent = () => {

  let skillsContent = skills.map((skill, index) => {
    let title = skill.name
    if (!isNil(skill.url)) {
      title = <a target="_blank" href={skill.url}>{skill.name}</a>
    }

    // star
    // <i class="fa-sharp fa-solid fa-star"></i>

    // half-star
    // <i class="fa-sharp fa-regular fa-star-half-stroke"></i>


    return (
      <div key={index} className='skill'>
        <h5 id={`skill-${index}`}><span className="skill-title">{title}: </span><StarComponent stars={skill.stars} /></h5>
      </div>
    )})

  return (
    <div key={'skills'} className=''>
      <a id="skills">
        <h2 id="skills">Skills</h2>
        </a>
      { skillsContent }
      <hr/>
    </div>
  )
}

export default ProjectsContent
