// import json file of repos contributed to
import { isNil } from 'lodash'
const contributions = require('./external_prs.json')


const charLimit = 250;


const MainContent = () => {

  let prcount = 0;
  let mergedcount = 0
  let repocount = 0;
  console.log('render opensource')
  let noHideContributions = contributions.filter((repo) => {
    return repo.hide !== true
  })

  noHideContributions = noHideContributions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


  let osContent = noHideContributions.map((repo, index) => {
    return (
      <div key={index} className='oss_rep  mt-2'>
        <a id={`oss-repo-${index}`}></a>
        <h4 id={`oss-repo-${index}`}>Project: <a href={repo.parent.html_url} target="_blank">{repo.parent.full_name}</a></h4>
        <p className="repo-description">{repo.description}</p>
        { repo.commentary && <p className='commentary font-italic'><strong>Commentary:</strong> {repo.commentary}</p>}
        <div className="my_pull_requests">
          <p><strong>My Pull Requests:</strong></p>
          <ul>
            
          { repo.prs.map((pr, prindex) => {
            if (prindex === 0) {
              repocount += 1
            }
            prcount += 1
            if (pr.pull_request.merged_at !== null) {
              mergedcount += 1
            }
            const truncatedText = pr.body.slice(0, charLimit); // Get the first charLimit characters
            const displayText = pr.body.length > charLimit ? truncatedText + '...' : truncatedText;
          

            return (<li key={`${index}-pr-${prindex}`}>
              <h5><a href={pr.html_url} target="_blank">{pr.title}</a></h5>
              {(!isNil(pr.commentary) && <p>{pr.commentary}</p>)}
              <p className='pr_body'>{displayText}</p>
            </li>)
          })}
          </ul>
        </div>
        <hr/>
      </div>
    )})

  return (
    <div key={'projects'} className=' mt-5'>
      <a id="opensource">
        <h2 id="opensource">Open Source Contributions</h2>
        </a>
      <h5>Total PRs: {prcount}.  Merged {mergedcount} over {repocount} repos.</h5>
      <p>This an extensive list of pull requests I've submitted to open source projects since 2011.<br/>Most of these PRs are bug-fixes in libraries I was using. I've excelled at diving into new codebases, fixing problems, adding test coverage, and lobbying maintainers to approve changes.</p>
      <hr/>
      { osContent }
    </div>
  )
}

export default MainContent
