import './content.css'
import Projects from './Projects';
import OpenSource from './OpenSource';
import Skills from './Skills'
import Education from './Education'
import WorkHistory from './WorkHistory'

const DummyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

const MainContent = () => {

  // Who am I
  // Work History
  // Education
  // Skills

  return (
    <div className='content'>
      <h1>Peter Conerly</h1>
      <div className='whoiam'>
        <h2 id="whoiam" >Who I am</h2>
        <p>{"My passion for software development comes from a drive to problem solve."}<br/> 
        {"I love being able to share the digital tools I've created. I feel energized when I'm collaborating with teams and launching products!"}</p>
        <p>Contact: <code>{'{firstInitial}{lastName} at gmail dot com'}</code></p>
        <p><a href="https://github.com/pconerly" target="_blank">Github</a></p>
      </div>
      <hr/>
      <WorkHistory />
      <Education />
      <Skills />
      <Projects />
      <OpenSource />
    </div>
  )
}

export default MainContent
