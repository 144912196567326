import { useHeadsObserver } from './hooks'
import './tableOfContent.css'
import { useEffect, useState } from 'react'
import * as cn from 'classnames'

const getClassName = (level) => {
  switch (level) {
    case 2:
      return 'head2'
    case 3:
      return 'head3'
    case 4:
      return 'head4'
    default:
      return null
  }
}

function TableOfContent() {
  const [headings, setHeadings] = useState([])
  const [collapsed, setCollapsed] = useState(true)
  const {activeId} = useHeadsObserver()

  console.log('headings', headings)

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("h2, h3, h4"))
      .map((elem) => ({
        id: elem.id,
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1))
      }))
    setHeadings(elements)
  }, [])
  // <button onClick={(e) => {
  //   e.preventDefault()
  //   setCollapsed(!collapsed)
  // }}>{collapsed ? 'Open' : 'Close'}</button>
  
  return (
    <>
    <nav className='toc'>
      <ul className={cn({'collapsed': collapsed})}>
        {headings.map(heading => (
          <li
            key={heading.id}
            className={getClassName(heading.level)}
            >
            <a
              href={`#${heading.id}`} 
              onClick={(e) => {
                e.preventDefault()
                document.querySelector(`#${heading.id}`).scrollIntoView({
                  behavior: "smooth"
                })
                setCollapsed(true)
              }}
                style={{
                  fontWeight: activeId === heading.id ? "bold" : "normal" 
                }}
              >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
    </>
  )
  
}

export default TableOfContent